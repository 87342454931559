<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form action="#" @submit.prevent="submit">
          <div class="form-body">
            <h3 class="card-title">Surat masuk</h3>
            <hr>
            <div class="row p-t-20">
              <div class="col-md-4">
                <label>
                  Agenda Surat masuk
                  <small style="color:red">*</small>
                </label>
                <div>
                  <Input
                    v-model="payload.agenda"
                    name="agenda_surat_masuk"
                    disabled
                    required
                    type="text"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <label :class="['control-label']">
                  Sifat Surat
                  <small style="color:red">*</small>
                </label>
                <div :class="[{ 'invalid': invalidSifatSurat }]">
                  <Multiselect
                    v-model="payload.sifat"
                    label="text"
                    track-by="value"
                    placeholder="Pilih Sifat Surat"
                    open-direction="bottom"
                    :options="dropdownSifatSurat.urgencyList"
                    :multiple="false"
                    :searchable="true"
                    :value="dropdownSifatSurat.value"
                    :loading="dropdownSifatSurat.isLoadingSifatSurat"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @close="onTouchSifatSurat"
                    @input="onChangeSifatSurat"
                    @search-change="sifatSurat"
                  >
                    <span slot="noResult">Oops! No Data found.</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="invalidSifatSurat"
                  >Must have at least one value</label>
                </div>
              </div>
              <div class="col-md-4">
                <label>
                  Tanggal
                  <small style="color:red">*</small>
                </label>
                <div>
                  <Input v-model="payload.tanggal" name="tanggal" required type="date"/>
                </div>
              </div>
              <div class="col-md-4">
                <label>
                  Tanggal Diterima
                  <small style="color:red">*</small>
                </label>
                <div>
                  <Input v-model="payload.tanggal_diterima" name="tanggal" required type="date"/>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <label>
                  Lampiran
                  <small style="color:red">*</small>
                </label>
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <Input
                      v-model="payload.jumlah"
                      placeholder="Jumlah"
                      name="jumlah"
                      required
                      type="number"
                    />
                  </div>
                  <div :class="['col-md-6 m-l-5',{ 'invalid': isInvalidLampiran }]">
                    <Multiselect
                      v-model="payload.lampiran"
                      label="text"
                      track-by="value"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="dropdownLampiran.lampiranList"
                      :multiple="false"
                      :value="dropdownLampiran.value"
                      :searchable="true"
                      :loading="dropdownLampiran.isLoadingJenisLampiran"
                      :internal-search="false"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :options-limit="300"
                      :max-height="600"
                      :show-no-results="true"
                      :hide-selected="true"
                      :selectLabel="''"
                      @input="onChangeLampiran"
                      @close="onTouchLampiran"
                      @search-change="jenisLampiran"
                    >
                      <span slot="noResult">Oops! No User found.</span>
                    </Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="isInvalidLampiran"
                    >Must have at least one value</label>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <label :class="['control-label']">
                  Jenis Surat
                  <small style="color:red">*</small>
                </label>
                <div :class="[{ 'invalid': isInvalidJenisSurat }]">
                  <Multiselect
                    v-model="payload.jenis"
                    label="text"
                    track-by="value"
                    placeholder="Pilih Jenis Surat"
                    open-direction="bottom"
                    :options="dropdownJenisSurat.categoryList"
                    :multiple="false"
                    :value="dropdownJenisSurat.value"
                    :searchable="true"
                    :loading="dropdownJenisSurat.isLoadingJenisSurat"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @input="onChangeJenisSurat"
                    @close="onTouchJenisSurat"
                    @search-change="jenisSurat"
                  >
                    <span slot="noResult">Oops! No Data found.</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="isInvalidJenisSurat"
                  >Must have at least one value</label>
                </div>
              </div>
              <div class="col-md-4">
                <label :class="['control-label']">
                  Nama Dokumen
                  <small style="color:red">*</small>
                </label>
                <div :class="[{ 'invalid': isInvalidNamaDokumen }]">
                  <Multiselect
                    v-model="payload.nama"
                    label="text"
                    track-by="value"
                    placeholder="Pilih Nama Dokumen"
                    open-direction="bottom"
                    :options="dropdownNamaDokumen.nameList"
                    :multiple="false"
                    :searchable="true"
                    :value="dropdownNamaDokumen.value"
                    :loading="dropdownNamaDokumen.isLoadingNamaDokumen"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @close="onTouchNamaDokumen"
                    @input="onChangeNamaDokumen"
                    @search-change="namaDokumen"
                    @select="nomorDokumen"
                  >
                    <span slot="noResult">Oops! No Data found.</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="isInvalidNamaDokumen"
                  >Must have at least one value</label>
                </div>
              </div>
            </div>

            <hr class="m-t-40">
            <div class="row">
              <div class="col-md-4">
                <label>
                  Nomor Dokumen
                  <small style="color:red">*</small>
                </label>
                <div>
                  <Input
                    v-model="payload.nomor_dokumen"
                    name="nomor_dokumen"
                    disabled
                    required
                    type="text"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <label>
                  Perihal
                  <small style="color:red">*</small>
                </label>
                <div>
                  <Input v-model="payload.perihal" name="perihal" required type="text"/>
                </div>
              </div>
            </div>

            <hr class="m-t-40">
            <div class="row">
              <div class="col-md-4">
                <label>Dari</label>
                <div class="row no-gutters">
                  <div class="col-md-2">
                    <button class="btn btn-icon btn-success m-t-3">
                      <i class="mdi mdi-plus"></i>
                    </button>
                  </div>
                  <div :class="['col-md-9',{ 'invalid': dropdownDari.isInvalidDari }]">
                    <Multiselect
                      v-model="payload.dari"
                      label="text"
                      track-by="id"
                      placeholder="Dari"
                      open-direction="bottom"
                      :options="dropdownDari.userList"
                      :multiple="false"
                      :searchable="true"
                      :loading="dropdownDari.isLoadingUserFind"
                      :internal-search="false"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :options-limit="300"
                      :max-height="600"
                      :show-no-results="true"
                      :hide-selected="true"
                      @search-change="asyncFind"
                    >
                      <span slot="noResult">Oops! No User found.</span>
                    </Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="dropdownDari.isInvalidDari"
                    >Must have at least one value</label>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <label>Unit Kerja Yang dituju</label>
                <div>
                  <Input
                    v-model="payload.tempat"
                    placeholder="Unit Kerja Yang dituju"
                    disabled
                    name="tempat"
                    required
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label>Tujuan</label>
                <div class="row no-gutters">
                  <div class="col-md-2">
                    <button class="btn btn-icon btn-success m-t-3">
                      <i class="mdi mdi-plus"></i>
                    </button>
                  </div>
                  <div :class="['col-md-9',{ 'invalid': dropdownTujuan.isInvalidTujuan }]">
                    <Multiselect
                      v-model="payload.kepada"
                      label="text"
                      track-by="value"
                      placeholder="Tujuan"
                      open-direction="bottom"
                      :options="dropdownTujuan.userList"
                      :multiple="true"
                      :searchable="true"
                      :loading="dropdownTujuan.isLoadingUserFind"
                      :internal-search="false"
                      :clear-on-select="true"
                      :close-on-select="false"
                      :options-limit="300"
                      :max-height="600"
                      :show-no-results="true"
                      :hide-selected="true"
                      @search-change="getUnit"
                    >
                      <span slot="noResult">Oops! No User found.</span>
                    </Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="dropdownTujuan.isInvalidTujuan"
                    >Must have at least one value</label>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <label>Unit Kerja Yang dituju</label>
                <div>
                  <Input
                    v-model="payload.tempat"
                    placeholder="Unit Kerja Yang dituju"
                    disabled
                    name="tempat"
                    required
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label>Tembusan</label>
                <div class="row no-gutters">
                  <div class="col-md-2">
                    <button class="btn btn-icon btn-success m-t-3">
                      <i class="mdi mdi-plus"></i>
                    </button>
                  </div>
                  <div :class="['col-md-9',{ 'invalid': dropdownTembusan.isInvalidTembusan }]">
                    <Multiselect
                      v-model="payload.tembusan"
                      label="text"
                      track-by="value"
                      placeholder="Tembusan"
                      open-direction="bottom"
                      :options="dropdownTujuan.userList"
                      :multiple="true"
                      :searchable="true"
                      :loading="dropdownTembusan.isLoadingTembusan"
                      :internal-search="false"
                      :clear-on-select="true"
                      :close-on-select="false"
                      :options-limit="300"
                      :max-height="600"
                      :show-no-results="true"
                      :hide-selected="true"
                      @search-change="getUnit"
                    >
                      <span slot="noResult">Oops! No User found.</span>
                    </Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="dropdownTembusan.isInvalidTembusan"
                    >Must have at least one value</label>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <label>Unit Kerja Tembusan Yang dituju</label>
                <div>
                  <Input
                    v-model="payload.tempat"
                    placeholder="Unit Kerja Yang dituju"
                    disabled
                    name="tempat"
                    required
                    type="text"
                  />
                </div>
              </div>
            </div>

            <hr class="m-t-40">

            <div class="row">
              <div class="col-md-12 m-t-10 m-b-10">
                <wysiwyg v-model="myHTML"/>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label :class="['control-label']">File Lampiran</label>
                  <Upload v-model="payload.file" is-file name="photo"/>
                </div>
              </div>
            </div>
          </div>
          <div class="form-actions">
            <button type="submit" class="btn btn-info" @click="setApproval(0)" title="Simpan">
              <i class="fa fa-check"></i> Save
            </button> &nbsp;
            <button type="submit" class="btn btn-success" @click="setApproval(1)" title="Kirim">
              <i class="mdi mdi-send"></i> Send
            </button> &nbsp;
            <button type="button" @click="goBack" class="btn btn-inverse" title="Cancel">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "../../../components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "../../../components/input/upload.vue";
import axios from "axios";
import store from "../../../store";

export default {
  components: {
    Input,
    RotateSquare5,
    Upload,
    Multiselect
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.documentInRejected;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    isInvalidNamaDokumen() {
      return (
        this.dropdownNamaDokumen.isTouched &&
        this.dropdownNamaDokumen.value.length === 0
      );
    },
    isInvalidJenisSurat() {
      return (
        this.dropdownJenisSurat.isTouched &&
        this.dropdownJenisSurat.value.length === 0
      );
    },
    invalidSifatSurat() {
      return (
        this.dropdownSifatSurat.isTouched &&
        this.dropdownSifatSurat.value.length === 0
      );
    },
    isInvalidDari() {
      return (
        this.dropdownDari.isTouched &&
        this.dropdownDari.value.length === 0
      );
    },
    isInvalidLampiran() {
      return (
        this.dropdownLampiran.isTouched &&
        this.dropdownLampiran.value.length === 0
      );
    }
  },
  data() {
    return {
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable"
      ],
      payload: {
        tanggal: "",
        tanggal_diterima: "",
        agenda: "",
        dari: "",
        sifat: [],
        jenis: [],
        dari: [],
        kepada: [],
        nama: [],
        lampiran: [],
        tembusan: [],
        perihal: "",
        referensi: "",
        valueJenisDokumen: [],
        valueSifatSurat: [],
        keterangan: "",
        valueIndeks: [],
        nomor_dokumen: "",
        superAdmin: "",
        file: "",
        jumlah: 0,
        persetujuan: 0
      },
      dropdownSifatSurat: {
        isTouched: false,
        urgencyList: [],
        value: [],
        isLoadingSifatSurat: false
      },
      dropdownJenisSurat: {
        isTouched: false,
        categoryList: [],
        value: [],
        isLoadingJenisSurat: false
      },
      dropdownLampiran: {
        isTouched: false,
        lampiranList: [],
        value: [],
        isLoadingJenisLampiran: false
      },
      dropdownNamaDokumen: {
        isTouched: false,
        nameList: [],
        value: [],
        isLoadingNamaDokumen: false
      },
      dropdownTujuan: {
        isTouched: false,
        userList: [],
        value: [],
        isLoadingUserFind: false
      },
      dropdownDari: {
        isTouched: false,
        userList: [],
        value: [],
        isLoadingUserFind: false
      },
      dropdownTembusan: {
        isTouched: false,
        value: [],
        tembusanList: [],
        isLoadingTembusan: false
      },
      myHTML: "",
      myFile: [],
      isDisabled: false
    };
  },
  async mounted() {
    this.initialize();
    this.getAllReference();
  },

  methods: {
    setApproval(value){
      this.payload.persetujuan = value;
    },
    getAllReference() {
      var query = "";
      this.asyncFind(query);
      this.sifatSurat(query);
      this.jenisSurat(query);
      this.getUnit(query);
      this.namaDokumen(query);
      this.jenisLampiran(query);
    },
    async initialize() {
      try {
        if (this.editMode) {
          axios.get(`/document_in/${this.$route.params.id}`).then(response => {
            const state = {
              loaded: true
            };
            this.$store.commit("documentInRejected/STATE", state);
            this.edit(response.data.data);
          });
        } else {
          this.$store.dispatch("documentInRejected/onAdd");
          axios.get(`/document_in/new`).then(response => {
            this.payload.agenda = response.data.data.agenda_number;
            this.payload.tanggal = response.data.data.document_date;
            this.payload.tanggal_diterima = response.data.data.receive_date;
          });
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      this.payload = {
        nomor_dokumen: data.document_number,
        tanggal: data.document_date,
        tanggal_diterima: data.receive_date,
        agenda: data.agenda_number,
        jenis: data.document_category,
        perihal: data.subject,
        jumlah: data.attachment_total,
        lampiran: data.attachment_type,
        sifat: data.document_urgency,
        nama: data.document_name,
        dari: data.sender,
        kepada: data.receiver,
        tembusan: data.carbon,
        file: data.file,
      };
      this.dropdownSifatSurat.value = data.document_urgency;
      this.dropdownJenisSurat.value = data.document_category;
      this.dropdownLampiran.value = data.attachment_type;
      this.dropdownNamaDokumen.value = data.document_name;
      this.dropdownTujuan.value = data.receiver;
      this.dropdownTembusan.value = data.carbon;
      this.dropdownDari.value = data.sender;
      this.myHTML = data.description;
    },
    goBack() {
      this.$store.dispatch("documentInRejected/onCancel");
    },
    submit() {
      if (
        this.dropdownSifatSurat.value.length === 0 &&
        this.dropdownJenisSurat.value.length === 0 &&
        this.dropdownLampiran.value.length === 0 &&
        this.dropdownNamaDokumen.value.length === 0
      ) {
        this.dropdownSifatSurat.isTouched = true;
        this.dropdownJenisSurat.isTouched = true;
        this.dropdownLampiran.isTouched = true;
        this.dropdownNamaDokumen.isTouched = true;
        window.scrollTo(0, 0);
      }
      if (this.payload.file) {
        this.myFile = this.payload.file.split(",");
      }
      const payload = {
        sender: this.payload.dari,
        receiver: this.payload.kepada,
        carbon: this.payload.tembusan,
        agenda_number: this.payload.agenda,
        receive_date: this.payload.tanggal_diterima,
        document_date: this.payload.tanggal,
        document_urgency: this.payload.sifat,
        document_category: this.payload.jenis,
        subject: this.payload.perihal,
        document_name: this.payload.nama,
        document_number: this.payload.nomor_dokumen,
        description: this.myHTML,
        file: this.myFile[1],
        attachment_total: this.payload.jumlah,
        attachment_type: this.payload.lampiran,
        send: this.payload.persetujuan
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then(success => {
        if (
          success &&
          !this.isInvalidJenisSurat &&
          !this.isInvalidNamaDokumen &&
          !this.isInvalidLampiran &&
          !this.invalidSifatSurat
        ) {
          if (this.editMode) {
            this.$store.dispatch("documentInRejected/submitEdit", {
              data,
              id: this.$route.params.id
            });
          } else {
            this.$store.dispatch("documentInRejected/submitAdd", data);
          }
        }
      });
    },
    clearAll() {
      this.payload.kepada = [];
    },
    asyncFind(query) {
      this.dropdownDari.isLoadingUserFind = true;
      axios
        .post(`users/findUserTo`, { search: query })
        .then(res => {
          this.dropdownDari.userList = res.data.data;
          this.dropdownDari.isLoadingUserFind = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    getUnit(query) {
      this.dropdownTujuan.isLoadingUserFind = true;
      axios
        .get(`units/list?s=${query}`)
        .then(res => {
          this.dropdownTujuan.userList = res.data.items;
          this.dropdownTujuan.isLoadingUserFind = false;
          })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    sifatSurat(query) {
      this.dropdownSifatSurat.isLoadingSifatSurat = true;
      axios
        .get(`document_urgencies/list?s=${query}`)
        .then(res => {
          this.dropdownSifatSurat.urgencyList = res.data.items;
          this.dropdownSifatSurat.isLoadingSifatSurat = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    jenisSurat(query) {
      this.dropdownJenisSurat.isLoadingJenisSurat = true;
      axios
        .get(`document_categories/list?s=${query}`)
        .then(res => {
          this.dropdownJenisSurat.categoryList = res.data.items;
          this.dropdownJenisSurat.isLoadingJenisSurat = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    namaDokumen(query) {
      this.dropdownNamaDokumen.isLoadingNamaDokumen = true;
      axios
        .get(`document_statuses/list?s=${query}`)
        .then(res => {
          this.dropdownNamaDokumen.nameList = res.data.items;
          this.dropdownNamaDokumen.isLoadingNamaDokumen = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    jenisLampiran(query) {
      this.dropdownLampiran.isLoadingJenisLampiran = true;
      axios
        .get(`attachment_types/list?s=${query}`)
        .then(res => {
          this.dropdownLampiran.lampiranList = res.data.items;
          this.dropdownLampiran.isLoadingJenisLampiran = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    onTouchNamaDokumen() {
      this.dropdownNamaDokumen.isTouched = true;
    },
    onTouchLampiran() {
      this.dropdownLampiran.isTouched = true;
    },
    onTouchJenisSurat() {
      this.dropdownJenisSurat.isTouched = true;
    },
    onTouchSifatSurat() {
      this.dropdownSifatSurat.isTouched = true;
    },
    onChangeSifatSurat(value) {
      this.dropdownSifatSurat.value = value;
    },
    onChangeLampiran(value) {
      this.dropdownLampiran.value = value;
    },
    onChangeJenisSurat(value) {
      this.dropdownJenisSurat.value = value;
    },
    onChangeNamaDokumen(value) {
      this.dropdownNamaDokumen.value = value;
    },
    nomorDokumen(value) {
      this.payload.nomor_dokumen = value.document_number;
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

